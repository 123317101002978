import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import t from '../lib/translate';

import Layout from '../layouts/default';
import Helmet from '../components/helmet';
import Breadcrumb from '../component-elements/breadcrumb';
import CmsMulticolumnBrand from '../brands-component/content-elements/CmsMulticolumnBrand';
import ProductGrid from '../components/shop-category/product-grid';
import ContentHeaderCms from '../content-elements/contentHeaderCms';

const BrandCollectionDetailTemplate = ({
  data: {
    datoCmsProductOccasionCollection: occasionCollection,
  },
  pageContext: {
    basePath,
    lang,
    brandMagentoId,
    pages,
    page,
  },
  location,
}) => {
  const breadcrumbParentPath = [
    { id: brandMagentoId.toString(), name: occasionCollection.brand.name, url: occasionCollection.brand.fullUrlPath },
    {
      id: `${brandMagentoId}-collection`,
      name: t`breadcrumb_brand_colletion_item_label`,
      url: occasionCollection.brand.collectionsUrlPath,
    },
  ];
  const today = Date.now()/1000;

  const staticFilters = [{
    key: 'jr_brand',
    values: [brandMagentoId],
  }];

  if(occasionCollection.magentoId) {
    staticFilters.push({
      key: 'jr_occasion',
      values: [occasionCollection.magentoId], // magento occasion set
    })
  }
  if(occasionCollection.automaticCollection && occasionCollection.automaticCollection === 'new' ) {
    staticFilters.push({
      key: 'dw_news_to_date',
      values: [`${today}_`], //to date is in future
    });
    staticFilters.push({
      key: 'dw_news_from_date',
      values: [`_${today}`], //from date is in past
    });
  }
  if(occasionCollection.automaticCollection && occasionCollection.automaticCollection === 'limited' ) {
    staticFilters.push({
      key: 'limited',
      values: [2128], //option id in magento: equals "true"
    })
  }
  if(occasionCollection.automaticCollection && occasionCollection.automaticCollection === 'others' ) {
    staticFilters.push({
      key: 'dw_has_article_line',
      values: [0], //has no article line
    })
  }

  return (
    <Layout textcolorHeader="black" textcolorHeaderMobile="black">
      <Breadcrumb
        items={[
          ...breadcrumbParentPath,
          { id: occasionCollection.id, name: occasionCollection.name },
        ]}
      />

      <ContentHeaderCms>
        <h1>{occasionCollection.name}</h1>
      </ContentHeaderCms>

      <ProductGrid
        staticFilters={staticFilters}
        initialPageCount={pages}
        initialPage={page}
        items={occasionCollection.magentoProducts}
        lang={lang}
        location={location}
        initialItemTotal={occasionCollection.magentoProductsCount}
        breadcrumb={[
          ...breadcrumbParentPath,
          {
            id: occasionCollection.id,
            name: occasionCollection.name,
            url: basePath,
          },
        ]}
      />

      {occasionCollection?.content.map((content) => (
        <CmsMulticolumnBrand key={content.id} {...content} />
      ))}

      <Helmet
        tags={occasionCollection.seoMetaTags}
        metaRobots={occasionCollection.metaRobots}
        metaCanonical={occasionCollection.metaCanonical}
        title={occasionCollection.seo?.title}
        titleFallback={t('brand_occasion_collection_detail_seo_title_fallback', occasionCollection.name)}
        description={occasionCollection.seo?.description}
        descriptionFallback={t('brand_occasion_collection_detail_seo_description_fallback', occasionCollection.name)}
        url={basePath}
      />
    </Layout>
  );
};

export default BrandCollectionDetailTemplate;

BrandCollectionDetailTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  pageContext: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const query = graphql`
query brandOccasionDetailQuery($url: String, $lang: String, $brandMagentoId: Int!, $limit: Int, $skip: Int) {
    datoCmsProductOccasionCollection(url: {eq: $url}, locale: {eq: $lang}) {
        ...productOccasionCollectionFragment
        automaticCollection
        magentoId
        magentoProductsCount(brandMagentoId: $brandMagentoId)
        magentoProducts(brandMagentoId: $brandMagentoId, limit: $limit, skip: $skip) {
            ...shopProductListItem
        }
    }
}
`;
